<template>
	<v-list-item :to="{ name: 'MessagesChat', params: { id: chat.id } }">
		<v-list-item-avatar>
			<v-img :src="imgURL" />
		</v-list-item-avatar>

		<v-list-item-content>
			<v-list-item-title>
				<template v-if="companyName">
					{{ $t('offers.managedBy', { name: companyName }) }}
				</template>
				{{ chat.company.name }}
			</v-list-item-title>
			<v-list-item-subtitle class="text-caption">
				{{ title }}
			</v-list-item-subtitle>
		</v-list-item-content>
	</v-list-item>
</template>

<script>
export default {
	name: 'ChatsListItem',
	props: {
		chat: {
			type: Object,
			required: true,
		},
	},
	computed: {
		fromOffer() {
			if (!this.chat) return null
			return !!this.chat.offer
		},
		fromEvent() {
			if (!this.chat) return null
			return !!this.chat.event
		},
		imgURL() {
			if (!this.chat) return null
			if (this.fromOffer) return this.chat.offer.imgURL
			else return this.chat.event.imgURL
		},
		companyName() {
			if (!this.chat) return null
			if (this.fromOffer) return this.chat.offer.companyName
			else return this.chat.event.companyName
		},
		title() {
			if (!this.chat) return null
			if (this.fromOffer) return this.chat.offer.title
			else return this.chat.event.title
		},
	},
}
</script>
